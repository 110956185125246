<script setup>
import logo from '@/assets/images/avrillo-logo.png'
import { useAuthStore } from '~/store/auth'
import { useConveyancingCaseStore } from '~/store/conveyancing-case'
import { UserFilled, Expand } from '@element-plus/icons-vue'

const route = useRoute()

const authStore = useAuthStore()

const conveyancingCaseStore = useConveyancingCaseStore()

const client = computed(() => authStore.client)

const conveyancingCase = computed(() => conveyancingCaseStore.conveyancingCase)

const purchaseNavigationItems = computed(() => [
  { name: '1st Letter Documents to Sign', href: '/documents/purchase/first-letter?page=1&perPage=10&corr_type=SSL' },
  { name: 'Report on Title', href: '/documents/purchase/report-on-title?page=1&perPage=10&corr_type=SSL' },
])

const saleNavigationItems = computed(() => [
  { name: '1st Letter Documents to Sign', href: '/documents/sale/first-letter?page=1&perPage=10&corr_type=SSL' },
])

const navigationItems = computed(() => [
  { name: 'Milestones', href: '/', hidden: false },
  { name: 'Case History', href: '/case-history', hidden: false },
  {
    name: 'Purchase Documents',
    active: route.params?.matter_type === 'purchase',
    children: purchaseNavigationItems.value,
    hidden: conveyancingCase?.value?.matter_type_code?.toUpperCase() === 'S',
  },
  {
    name: 'Sale Documents',
    active: route.params?.matter_type === 'sale',
    children: saleNavigationItems.value,
    hidden: conveyancingCase?.value?.matter_type_code?.toUpperCase() === 'P',
  },
  { name: 'Payment', href: '/payment', hidden: conveyancingCase?.value?.matter_type_code?.toUpperCase() === 'S' },
])

function handleLogout() {
  authStore.logout()
}
</script>

<template>
  <header class="fixed inset-x-0 top-0 z-50 bg-white shadow">
    <div class="flex items-center justify-between gap-6 px-6 py-3 md:px-20">
      <nuxt-link to="/">
        <img :src="logo" alt="Logo" class="h-12" />
      </nuxt-link>
      <nav class="hidden items-center justify-center gap-6 md:flex">
        <template v-for="navigationItem in navigationItems" :key="navigationItem.href">
          <section v-if="!navigationItem.hidden">
            <el-dropdown v-if="!!navigationItem.children">
              <p
                :class="navigationItem.active ? 'active-dropdown' : ''"
                class="relative cursor-pointer text-sm font-semibold uppercase text-gray-900 outline-none hover:text-primary"
              >
                {{ navigationItem.name }}
              </p>
              <template #dropdown>
                <el-dropdown-menu>
                  <nuxt-link v-for="child in navigationItem.children" :key="child.href" :to="child.href">
                    <el-dropdown-item>
                      {{ child.name }}
                    </el-dropdown-item>
                  </nuxt-link>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <nuxt-link v-else :to="navigationItem.href" class="relative text-sm font-semibold uppercase text-gray-900 hover:text-primary">
              {{ navigationItem.name }}
            </nuxt-link>
          </section>
        </template>
      </nav>
      <div class="flex items-center gap-3">
        <div class="hidden text-right md:block">
          <el-skeleton :loading="!conveyancingCase?.con_case_ref" :rows="0" animated>
            <p class="text-sm font-bold">{{ conveyancingCase?.con_case_ref }}</p>
          </el-skeleton>
          <p class="text-xs text-gray-500">{{ client?.name }}</p>
        </div>
        <el-dropdown type="success" placement="bottom-end" size="large">
          <template #default>
            <div class="">
              <el-avatar :icon="UserFilled" class="hidden items-center justify-center md:flex" />
              <el-avatar :icon="Expand" class="flex items-center justify-center md:hidden" />
            </div>
          </template>
          <template #dropdown>
            <el-dropdown-menu>
              <div class="w-36 px-5 pb-2">
                <p class="text-sm font-bold">{{ conveyancingCase?.con_case_ref }}</p>
                <p class="truncate text-xs text-gray-500">{{ client?.name }}</p>
              </div>
              <hr />
              <template v-for="navigationItem in navigationItems" :key="navigationItem.route">
                <section v-if="!navigationItem.hidden">
                  <nuxt-link v-if="!navigationItem.children" :to="navigationItem.href">
                    <el-dropdown-item>
                      {{ navigationItem.name }}
                    </el-dropdown-item>
                  </nuxt-link>
                  <template v-else>
                    <hr />
                    <p class="px-3 py-2 text-xs">{{ navigationItem.name }}</p>
                    <nuxt-link v-for="item in navigationItem.children" :key="item.href" :to="item.href">
                      <el-dropdown-item>
                        {{ item.name }}
                      </el-dropdown-item>
                    </nuxt-link>
                    <hr />
                  </template>
                </section>
              </template>
              <el-dropdown-item @click="handleLogout">Logout</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>

<style scoped lang="postcss">
nav .router-link-active,
nav .active-dropdown {
  @apply text-primary !important;
}

nav .router-link-active::before,
nav .active-dropdown::before {
  @apply absolute -left-3 top-1/2 h-8 w-8 -translate-y-1/2 rounded-full bg-primary/20;
  content: '';
}

nav .el-dropdown {
  @apply flex !important;
}
</style>
